<template>
  <section>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <b-card>
          <b-card-title>
            <b-row>
              <b-col md="3">
                <div class="d-flex align-items-center">
                  <h3 class="page-title mb-n2">
                    Novo Contato
                    <!-- <span class="text-primary font-weight-bold"
                      >Novo Contato</span
                    > -->
                  </h3>
                </div>
              </b-col>
            </b-row>
          </b-card-title>

          <form class="forms-sample mt-5" @submit.stop.prevent="addContato">
            <!-- <div class="col-4"> -->

            <b-form-group
              label="Empresa"
              :class="showWhiteMode ? 'label__black' : ''"
              :style="showEmpresaError ? 'border-color: red' : ''"
            >
              <multiselect
                v-model="empresa"
                :options="options"
                placeholder="Nome da empresa ou CNPJ"
                selectLabel="Enter para selecionar"
                selectedLabel="Selecionado"
                deselectLabel="Enter para remover"
                @search-change="asyncFind"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                :searchable="true"
                :internal-search="false"
                :loading="isLoading"
                :label="'razao_social'"
              >
                <template slot="noOptions">Lista está vazia.</template>
              </multiselect>
              <span class="invalid-feedback d-inline" v-if="showEmpresaError">
                Preenchimento obrigatório</span
              >
            </b-form-group>

            <b-form-group
              label="Nome"
              :class="showWhiteMode ? 'label__black' : ''"
            >
              <input
                v-model.trim="newContact.name"
                class="form-control"
                placeholder="Nome"
              />
              <span class="invalid-feedback d-inline" v-if="showNameError"
                >Preenchimento obrigatório</span
              >
            </b-form-group>

            <b-form-group
              label="Cargo"
              :class="showWhiteMode ? 'label__black' : ''"
            >
              <input
                v-model.trim="newContact.cargo"
                class="form-control"
                placeholder="Cargo"
              />
            </b-form-group>

            <b-form-group
              label="Departamento"
              :class="showWhiteMode ? 'label__black' : ''"
            >
              <multiselect
                v-model="departamentoSelected"
                :options="departamentos"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="false"
                placeholder="Selecionar Depto."
                label="nome"
                track-by="id"
                selectLabel="Enter para selecionar"
                selectedLabel="Selecionado"
                deselectLabel="Enter para remover"
                @open="carregarDepartamentos"
                :loading="carregandoDepartamentos"
              >
                <template slot="noOptions">Lista está vazia.</template>
              </multiselect>
            </b-form-group>

            <b-form-group
              label="Nível Hierárquico"
              :class="showWhiteMode ? 'label__black' : ''"
            >
              <multiselect
                v-model="nivelHierarquicosSelected"
                :options="nivelHierarquicos"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="false"
                placeholder="Selecionar Nível"
                label="nome"
                track-by="id"
                selectLabel="Enter para selecionar"
                selectedLabel="Selecionado"
                deselectLabel="Enter para remover"
                @open="carregarNivelHierarquicos"
                :loading="carregandoNivelHierarquicos"
              >
                <template slot="noOptions">Lista está vazia.</template>
              </multiselect>
            </b-form-group>

            <b-form-group
              label="Linkedin "
              :class="showWhiteMode ? 'label__black' : ''"
            >
              <input
                v-model.trim="newContact.linkedin"
                class="form-control"
                placeholder="Linkedin"
              />
            </b-form-group>

            <!-- <b-form-group
                label="Telefones"
                :class="showWhiteMode ? 'label__black' : ''"
                class="mb-0"
              >
                <div
                  v-for="(telefone, index) in newContact.telefones"
                  :key="index"
                  class="d-flex mt-1"
                  :style="
                    !showWhiteMode ? 'background: #2a3038' : 'background: #fff'
                  "
                >
                  <input
                    class="form-control"
                    type="tel"
                    placeholder="Formato internacional +5511991929394"
                    
                    v-model="newContact.telefones[index]"
                  />
                  <button
                    v-if="index != 0"
                    class="btn"
                    @click="removeTelefone(index)"
                    type="button"
                  >
                    <i class="mdi mdi-delete text-danger"></i>
                  </button>
                </div>

                <div class="d-flex justify-content-end mt-2">
                  <button
                    class="btn btn-success"
                    v-on:click="newContact.telefones.push('')"
                    type="button"
                  >
                    +
                  </button>
                </div>
              </b-form-group> -->

            <!-- <b-form-group
                label="Emails"
                :class="showWhiteMode ? 'label__black' : ''"
                class="mb-0"
              >
                <div
                  v-for="(email, index) in newContact.emails"
                  :key="index"
                  class="d-flex mt-1 row_black"
                  :style="
                    !showWhiteMode ? 'background: #2a3038' : 'background: #fff'
                  "
                >
                  <input
                    v-model="newContact.emails[index]"
                    class="form-control"
                    type="email"
                    placeholder="Email"
                    
                  />

                  <button
                    v-if="index != 0"
                    class="btn"
                    @click="removeEmail(index)"
                  >
                    <i class="mdi mdi-delete text-danger"></i>
                  </button>
                </div>

                <div class="d-flex justify-content-end mt-2">
                  <button
                    class="btn btn-success"
                    type="button"
                    v-on:click="newContact.emails.push('')"
                  >
                    +
                  </button>
                </div>
              </b-form-group> -->

            <div class="d-flex justify-content-end">
              <b-button
                type="submit"
                variant="primary"
                class="mr-2 my-5"
                :disabled="loading"
              >
                <template v-if="loading">
                  <b-spinner small type="grow"></b-spinner>
                  Processando...
                </template>
                <template v-else> Confirmar </template>
              </b-button>
            </div>
            <!-- </div> -->
          </form>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
 /* eslint-disable */
// import empresaService from "../../../services/empresa.service";
import empresaService from "@/services/empresa.service";
import DepartamentoService from "@/services/departamento.service";
import NivelHierarquicoService from "@/services/nivelhieararquico.service";

import ContatoService from "@/services/contato.service";

import { mapState } from "vuex";

export default {
  props: ["id"],
  components: {},
  data() {
    return {
      showModal: false,
      newContact: {
        name: "",
        cargo: "",
        linkedin: "",
        emails: [""],
        telefones: [""],
      },
      loading: false,
      isLoading: false, //
      empresa: null,
      options: [],

      //departamentos abaixo
      departamentos: [],
      departamentoSelected: null,
      carregandoDepartamentos: false,

      // nivelHierarquicos
      nivelHierarquicos: [],
      nivelHierarquicosSelected: null,
      carregandoNivelHierarquicos: false,

      showNameError: false,
      showEmpresaError: false,
    };
  },

  methods: {
    asyncFind(name) {
      this.isLoading = true;

      if (isNaN(Number(name[0]))) {
        return this.findCompanyByName(name);
      }

      this.findCompanyByCNPJ(name);
    },

    findCompanyByName(name) {
      this.isLoading = true;

      empresaService
        .getAll({ q: name })
        .then((res) => {
          this.options = res.data.data;

          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
    findCompanyByCNPJ(CNPJ) {
      this.isLoading = true;
      //   console.log("cnpj");

      empresaService
        .getAll({ cnpjs: CNPJ })
        .then((res) => {
          this.options = res.data.data;

          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },

    removeTelefone(index) {
      if (confirm("Confirma a remoção desse Telefone ?"))
        return this.newContact.telefones.splice(index, 1);
    },
    removeEmail(index) {
      if (confirm("Confirma a remoção desse Email ?"))
        return this.newContact.emails.splice(index, 1);
    },

    carregarDepartamentos() {
      if (this.departamentos.length == 0) {
        this.carregandoDepartamentos = true;
        DepartamentoService.getAll().then((response) => {
          this.departamentos = response.data;
          this.carregandoDepartamentos = false;
          // console.log(response.data);
        });
      }
    },
    carregarNivelHierarquicos() {
      this.carregandoNivelHierarquicos = true;
      NivelHierarquicoService.getAll().then((response) => {
        this.nivelHierarquicos = response.data;
        this.carregandoNivelHierarquicos = false;
      });
    },
    addContato() {
      if (!this.validaForm()) return;

      const data = {
        empresa_id: this.empresa.id,
        nome: this.newContact.name,
        cargo: this.newContact.cargo,
        departamento_id: this.departamentoSelected
          ? this.departamentoSelected.id
          : null,
        nivel_hierarquico_id: this.nivelHierarquicosSelected
          ? this.nivelHierarquicosSelected.id
          : null,
        linkedin: this.newContact.linkedin,

      };

      // console.warn(this.empresa.cnpj);



      


  

      ContatoService.saveOrUpdate(data)
        .then((res) => {
          const id_contato = res.data.data.id;         

          // this.makeToast("success", "Cadastrado", res.data.message);



          this.$router.push({
            name: 'EditContato',            
            params: { id: id_contato, new_contact: true } 
          });


        })
        
        .catch((erro) => {
          console.error(erro);
          
        });
        

      this.showNameError = false;
      this.showEmpresaError = false;
    },

    validaForm() {
      
      if (!this.newContact.name) {
        this.showNameError = true;
      }
      if (!this.empresa) {
        this.showEmpresaError = true;
      }

      if (!this.empresa || !this.newContact.name) return false;

      return true;
    },

    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  created() {},
  mounted() {
    this.findCompanyByName();
    this.carregarDepartamentos();
    this.carregarNivelHierarquicos();

  },
  computed: {
    ...mapState(["showWhiteMode"]),
  },
  watch: {
    
  },
};
</script>

<style  >
/* .col-form-label, legend{
    color: red !important;
  } */

.label__black {
  color: rgb(0, 0, 0);
}
</style>


<style scoped lang="scss">
section {
  max-width: 80%;
  margin: auto;
}
.forms-sample {
  /* max-width: 80%; */
  margin: auto;

  button {
    margin-right: 0 !important;
  }
}
</style>